import React from 'react';
import styles from './index.module.less';
import BaseLinearContent from '@/components/Common/BaseLinearContent';
import classNames from 'classnames';
import Image from 'next/image';

const ChooseContent = ({ pageContent, data }: { pageContent?: any; data?: any }) => {
  const newMap = new Map<string, any>([
    [
      'virtual-hair-color-try-on',
      {
        h2: 'Why Choose Hairpaca?',
        h3List: [
          'Instantly Change Your Hair Color',
          'Virtual Try On Different Hair Colors',
          'Perfect for Any Occasion',
          'Try It Anytime, Anywhere',
        ],
      },
    ],
    [
      'hair-types',
      {
        h2: 'Why Choose Hairpaca?',
        h3List: [
          'Explore Hairstyles for All Hair Types',
          'See It on Yourself First',
          'Confidence in Every Choice',
          'Avoid Hair Disasters',
        ],
      },
    ],
    [
      'best-hairstyles-for-face-shape',
      {
        h2: 'Why Choose Hairpaca for Hairstyles for Your Face Shape?',
        h3List: [
          'Virtual Try-On Experience',
          'Find the Most Flattering Hairstyle',
          'Easy & Risk-Free Styling',
          'Style on Demand – Anytime, Anywhere',
        ],
      },
    ],
    [
      'trending-hairstyles',
      {
        h2: 'Why Choose Hairpaca for Trending Hairstyles?',
        h3List: [
          'Explore the Latest Trending Hairstyles',
          'AI-Powered Try-On for Instant Haircut Ideas',
          'Find the Best Trending Hairstyles for 2025',
          'Try It Anytime, Anywhere',
        ],
      },
    ],
  ]);

  return (
    <div className={styles.content}>
      <h2 style={{ display: 'none' }}>{newMap.get(data?.pathId)?.h2}</h2>
      <div className={styles.h2}>
        <span>{pageContent?.contentInfo?.['section-why']?.title}</span>
      </div>

      <BaseLinearContent>
        <div className={styles.container}>
          {pageContent?.contentInfo?.['section-why']?.cards?.map((item: any, index: number) => {
            return (
              <div className={styles.cardContainer} key={index + item.title}>
                <Image
                  src={item.imgPath}
                  alt={'icon'}
                  height={80}
                  width={80}
                  className={styles.icon}
                  loading="lazy"
                />

                <p className={classNames(styles.title)}>{item.title}</p>
                <p className={styles.desc} dangerouslySetInnerHTML={{ __html: item.desc }} />
              </div>
            );
          })}
        </div>
      </BaseLinearContent>
      <ul style={{ display: 'none' }}>
        {newMap.get(data?.pathId)?.h3List.map((item: any, index: number) => {
          return <h3 key={index + item}>{item}</h3>;
        })}
      </ul>
    </div>
  );
};

export default ChooseContent;
